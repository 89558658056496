import React from 'react'
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import '../global.css';
import Image from '../components/image';

import Layout from "../components/layout"
import SEO from "../components/seo"

const contact = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Image></Image>
      <div class="contact-space">
        <h1>Contact Us</h1>
        <p>Email: info@spectrumplay.com</p>
        <p>Office Phone: (703) 349-2430</p>
        <p>Address: Fairfax, VA</p>
      </div>
    </Layout>
  )
}

export default contact
